@import "global-styles";

.app-footer {
  bottom: 0;
  flex-shrink: 0;

  .footer-light {
    background-color: $color-white;
    border-top: 5px solid $color-secondary;
    padding: 1rem 0;

    .link {

      a {
        display: block;
        padding-top: .5rem;
        padding-bottom: .5rem;
        color: $color-teal-dark;
        font-size: .875rem;
        font-weight: 500;
        width: fit-content;

        @include sco-breakpoint($sco-sm) {
          display: inline-block;
        }
      }

      .pipe {
        display: none;
        padding: 0 .5rem;
        color: $color-border-light;

        @include sco-breakpoint($sco-sm) {
          display: inline-block;
        }
      }

      &:last-child .pipe {
        display: none;
      }

      .display-inline {
        display: inline-block !important;
      }
    }
  }

  .footer-dark {
    background-color: $color-primary;
    padding: 2rem 0;
    color: $color-white;

    .sco-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @include sco-breakpoint($sco-sm) {
        flex-direction: row;
      }

      .safeco-logo {
        margin: 0 0 1.75rem 0;

        @include sco-breakpoint($sco-sm) {
          margin: 0 2rem 0 0;
        }
      }

      .copyright {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        font-size: .75em;

        div:first-child {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
