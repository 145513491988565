@import "global-styles";

.shared-tooltip {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 100%;
    
    .tooltip-wrapper {
        width: 100%;
        display: inline-flex;
        position: relative;
        align-items: center;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        z-index: 2;

        .tooltip-row {
            width: 100%;
            display: flex;
            flex-direction: row;

            .tooltip-text {
                white-space: nowrap;
                pointer-events: none;
            }
    
            .tooltip-content {
                position: absolute;
                text-align: left;
                font-size: 14px;
                padding: 16px;
                border-radius: 4px;
                z-index: 1;
                background-color: #FFFFFF;
                color: $text-color !important;
                box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.4);
    
                &.top {
                    bottom: 0;
                    margin-bottom: 51px;
                }
    
                &.bottom {
                    top: 0;
                    margin-top: 43px;
    
                    .bullet-spacing {
                        padding-top: 8px;
                        margin-left: -24px;
                    }
    
                    .line-spacing {
                        padding-top: 8px;
                        color: $text-color !important;
                    }
                }
    
                .tooltip-link {
                    pointer-events: all !important;
                    font-size: 14px;
                    display: inline;
                }
    
                .vehicle-usage {
                    margin: 16px 8px 24px 8px;
    
                    .vehicle-usage-header {
                        font-size: 18px;
                    }
    
                    .vehicle-usage-description {
                        margin-top: 8px;
                        margin-bottom: 8px;
                    }
                    
                    .mileage-estimator-table {
                        @include cam-table;
                        table {
                            border-collapse: collapse;
    
                            thead {
                                tr {
                                    th {
                                        color: $text-color;
                                        font-weight: bold;
                                        text-align: center;
                                        white-space: normal;
                                    }
                                }
                            }
                            tbody {
                                text-align: center;
                                font-size: 14px;
                                tr {
                                    &:nth-child(even) {
                                        background: #F5F5F5;
                                    }
    
                                    &.cam-table-update {
                                        &:nth-child(odd) {
                                            background: #FFFFFF;
                                            border: none;
                                        }
                    
                                        td {
                                            padding: 8px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .arrow-button-group {
                display: flex;
                flex-direction: column;

                .tooltip-arrow {
                    content: "";
                    position: relative;
                    margin-left: 6px;
                    left: 50%;
                    height: 14px;
                    width: 14px;
                    z-index: 1;
                    background: #FFFFFF;
                    transform: translateX(-50%) rotate(45deg);
        
                    &.top {
                        top: -38px;
                        box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.2);
                    }
        
                    &.bottom {
                        top: 36px;
                        box-shadow: -1px -1px 1px 0 rgba(0, 0, 0, 0.1);
                    }
                }
                
                .icon-group {
                    position: absolute;
                    margin-top: -8px;

                    &.ie-closed {
                        margin-top: -7px;
                    }
    
                    &.ie-opened {
                        margin-top: -21px;
                    }

                    .tooltip-icon {
                        position: absolute;
                        z-index: 1;
                        background-color: transparent;
                        cursor: pointer;
                        outline: none;
                        border: none;
                        padding: 0;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;

                        .button-adjustment {
                            position: absolute;
                            z-index: 1;
                            top: 50%;
                            left: 50%;
                            margin: -8px 0px 0px -8px;
                            cursor: pointer;
                            outline: none;
                            border: none;
                            padding: 0;
                        }

                        &.closed {
                            &:hover {
                                background-color: #F5F5F5;
                            }
                            
                            &:focus {
                                border: 2px solid #7EAFE7;
                                background-color: #F5F5F5;
                            }
                        }
            
                        &.opened {
                            background-color: #F5F5F5;
    
                            &:focus {
                                outline: none;
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }
}