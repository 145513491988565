@import "global-styles";

.text-alerts-page {
    max-width: 100%;
    @include sco-breakpoint($sco-sm) {
        max-width: 705px;
    }

    h2 {
        font-size: 2.25rem;
        font-weight: 300;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: .75rem;
    }

    .phone-number-input-container {
        .info-container {
            display: flex;
            max-width: 300px;
            padding: 4px 2px;
        }
        .phone-number, .change-phone-number {
            display: block;
            max-width: 300px;
        }
        .sco-label-textInput {
             margin: 0;
        }
    }

    .non-active {
        display: none;
    }

    .font-small {
        font-size: .75rem;
    }

    a.font-small, a.sco-link {
        color: #06748c;
    }

    .resend-button {
        margin-top: 2rem;
    }

    .text-link {
        width: 100%;
        white-space: nowrap;
    }

    .change-phone-number {
        .text-help, .text-error {
            position: absolute;
        }
    }

    .sco-button-group {
        margin-top: 3rem;
    }
}