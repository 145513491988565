@import "global-styles";

.way-finder {
   
    .header-progress-container {
        padding-top: 48px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        .header-progress-list {
            margin: 0;
            padding: 0;
            list-style-type: none;

            .header-progress-item {
                position: relative;
                display: inline-block;
                width: 90px;
                text-align: center;
                line-height: 4.75em;
                color: #343741;

                img {
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    left: 40px;
                    top: -4px;
                    z-index: 3;
                    @media only screen and (min-width: 400px) and (max-width: 600px) {
                        left: 51px;
                    }
                    @media only screen and (min-width: 601px) {
                        left: 61px;
                    }
                }

                span {
                    color: $color-border-medium;
                    font-weight: 700;
                    position: absolute;
                    left: 43px;
                    top: -35px;
                    z-index: 3;
                    @media only screen and (min-width: 400px) and (max-width: 600px) {
                        left: 54px;
                    }
                    @media only screen and (min-width: 601px) {
                        left: 64px;
                    }
                }

                @media only screen and (min-width: 400px) and (max-width: 600px) {
                    width: 116px;
                }
                @media only screen and (min-width: 601px) {
                    width: 135px;
                }

                &:before {
                    position: absolute;
                    z-index: 2;
                    top: -13px;
                    left: 31px;
                    height: 32px;
                    width: 32px;
                    border-radius: 16px;
                    line-height: 1.5em;
                    content: "";
                    color: white;
                    background-color: white;
                    border: solid 2px $color-border-medium;

                    @media only screen and (min-width: 400px) and (max-width: 600px) {
                        left: 42px;
                    }
                    @media only screen and (min-width: 601px) {
                        left: 52px;
                    }
                }

                &.complete:before {
                    background-color: $color-success-border;
                    border-color: $color-success-border;
                }

                &.active {
                    font-weight: bold;

                    &:before {
                        border-color: $color-success-border;
                    }
                    span {
                        color: $color-success-text;
                        font-weight: 700;
                    }
                }

                &.warning {
                    font-weight: bold;
                    
                    &:before {
                        background-color: $color-warning-border;
                        border-color: $color-warning-border;
                    }
                }

                &.error {
                    font-weight: bold;

                    &:before {
                        border-color: $color-error-border;
                    }
                    img {
                        width: 32px;
                        height: 32px;
                        top: -13px;
                        left: 31px;
                        @media only screen and (min-width: 400px) and (max-width: 600px) {
                            left: 42px;
                        }
                        @media only screen and (min-width: 601px) {
                            left: 52px;
                        }
                    }
                }

                .path {
                    position: absolute;
                    display: block;
                    z-index: 1;
                    top: 2px;
                    left: -29px;
                    height: 2px;
                    width: 60px;
                    content: "";
                    background-color: $color-border-medium;

                    @media only screen and (min-width: 400px) {
                        left: -67px;
                        width: 132px;
                    }
                }

                &:first-child .path {
                    display: none;
                }
            }
        }
    }
}
