@import "@lmig/safeco-ui/scss/grid";
@import "@lmig/lmds-tokens/dist/scss/core/color";
// @import '@angular/material/theming';
@import "variables";

/* Materia UI theme */
// @include mat-core();
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';

$color-primary: $lmig-color-libertyBlue; //#1a1446
$color-secondary: $lmig-color-libertyYellow; //#ffd000
$color-white: $lmig-color-white; //#ffffff

/* Standard Text Colors */
$text-color: $lmig-color-text-default; // #343741; content >= 16px (besides specialty content and interactions)
$text-color-light: $lmig-color-text-light; // #707070; labels and content <= 14px

/* accent Teal */
$color-teal-light: $lmig-color-teal10; //#f2fcfc
$color-teal: $lmig-color-teal80; //#99e5ea
$color-teal-medium: $lmig-color-tealMedium; //#28a3af
$color-teal-dark: $lmig-color-tealDark; //#06748c

/* System Alert Border Colors */
$color-error-border: $lmig-color-red-dark; //#d32f2f
$color-warning-border: $lmig-color-orange-dark; //#f06d00
$color-success-border:  $lmig-color-green-dark; //#03ac63
$color-information-border: $lmig-color-blue-dark; //#06748c
/* System Alert Text Colors */
$color-error-text: $lmig-color-red-dark; //#d32f2f
$color-warning-text: $lmig-color-orange-dark; //#f06d00
$color-success-text: $lmig-color-green-dark; //#03ac63
$color-information-text: $lmig-color-blue-dark; //#06748c
/* System Alert/Badge Background Colors */
$color-error-background: $lmig-color-red-light; //#fff4f5
$color-warning-background: #070707;
$color-success-background: $lmig-color-green-light; //#e1f2e6
$color-information-background: $lmig-color-blue-light;  //#d9e8fe

/* Border Colors */
$color-gray-10: $lmig-color-gray10; //#e6e6e6
$color-border-light: $lmig-color-gray29; //#c0bfc0
$color-border-medium: $lmig-color-gray73; //#707070
$color-border: $lmig-color-gray04;  //#f5f5f5

/* Button Colors */
$button-border-primary: #4399a8;
$button-fill-primary: $color-teal-medium;
$button-fill-primary-hover: #4399a8; 
$button-text-primary-hover: white;

//Offset for mobile to stretch cards to full width
$mobile-margin-offset: -24px;
@mixin mobile-margin-offset {
    @media (max-width: $sco-sm) {
        margin-left: $mobile-margin-offset;
        margin-right: $mobile-margin-offset;
    }
}

$card-margin: 24px;

@mixin card-style {
    background-color: white;
    margin-bottom: 24px;
    padding: $card-margin;
    border: 1px solid $color-border-light;
    border-radius: 3px;
    &.default {
        border-top: 5px solid $color-warning-text;
    }
    &.sco-border-top {
        border-top: 8px solid $color-primary
    }
    &.card-path { 
        .path-title { 
            margin-top: 0px;
            font-weight: 300;
            font-size: 16px;
            margin-bottom: 16px;
        }
        .content-title { 
            margin-top: 0px;
            font-size: 32px;
            font-weight: 300;
            margin-bottom: 24px; //may be 32 depending on if next content doesn't have supplied 8px padding
        }
    }
    &.header-padding {
        padding: 0 0 16px 0;
    }
    @include mobile-margin-offset;
}

@mixin reduced-width {
    max-width: 470px;
    margin: 0 auto;
}

a {
    cursor: pointer;
    color: $color-teal-dark;
    text-decoration: none;

    &.sco-textLink:not(.sco--standalone) {
        &:hover {
            color: $color-teal-dark;
        }
    }

    &:hover {
        text-decoration: underline;
    }
}

.form-select {
    border: none;
    padding: 0;
}

.sco-textLink {
    margin-bottom: 0;
}

@mixin cam-table {
    background: white;
    table {
        width: 100%;
        font-size: 16px;
        thead {
            tr {
                th {
                    font-weight: 400;
                    font-size: 14px;
                    color: $text-color-light;
                    padding-top: 16px;
                    padding-bottom: 8px;
                    padding-left: 8px;
                    padding-right: 8px;
                    white-space: nowrap;
                }
            }
        }
        tbody {
            color: $text-color;
            font-weight: 400;
            padding-left: 8px;
            tr {
                td {
                    border-top: none;
                    border-bottom: none;
                    padding: 8px;
                }
                &:nth-child(odd) {
                    background: #F5F5F5;
                    border-top: 1px solid $color-border-light;
                    border-bottom: 1px solid $color-border-light;
                }

                // new table styling that'll eventually be used throughout MMA
                &.cam-table-update {            // remove class after global UI updates
                    &:nth-child(odd) {
                        border: none;
                    }

                    td {
                        padding:24px;
                        vertical-align: middle;
                    }
                }
            }
        }
        .date-format {
            white-space: nowrap;
            text-align: left;
        }
        .currency-format {
            text-align: right;
        }
        @media (max-width: $sco-sm) {
            .desktop-only {
                display: none;
            }
        }
    }
    .view-controller-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 16px;
        padding-right: 16px;
        padding-left: 16px;
        .view-controller {
            display: flex;
            align-items: center;
            justify-content: center;
            .view-controller-text {
                margin-right: 8px;
            }
            .up-arrow {}
            .down-arrow {}
            &:hover {
                cursor: pointer;
            }
        }
    }
}

hr {
    margin-top: 24px;
    margin-bottom: 24px;
    border-top: 1px solid $color-border-light;
}

/* disable number type fields select arrows */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* sco-button enhancements */
.sco-button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 16px;
    @media (max-width: $sco-sm) {
        flex-direction: column-reverse;
    }

    &.nav-buttons {
        padding: 8px 0 16px 0;

        @media (max-width: $sco-sm) {
            margin-top: 8px;
            
            .next-button, .done-button {
                margin-bottom: 16px;
            }
        }      
    }
}
.sco-button {
  padding-left: 32px;
  padding-right: 32px;

  @media (max-width: $sco-sm) {
    width: 100%;
    max-width: 100%;
    margin-left: 0px;

    &:not(:last-of-type) {
      margin-top: 16px;
    }
  }

  &.sco--small {
    line-height: 0;
  }

  &.no-margin-right {
    margin-right: 0px;
  }

  &:disabled,
  &:disabled:hover {
    box-shadow: 0 3px 00 #c0bfc0 !important;
    -webkit-box-shadow: 0 3px 00 #c0bfc0 !important;
  }
}
.sco--outline {
  box-shadow: 0 3px 00 #c0bfc0;
  -webkit-box-shadow: 0 3px 00 #c0bfc0;
}

.sco--outline:hover {
  box-shadow: 0 3px 00 #1a1446;
  -webkit-box-shadow: 0 3px 00 #1a1446;
}

.sco--fill {
  box-shadow: 0 3px 00 #06748c;
  -webkit-box-shadow: 0 3px 00 #06748c;
}

.sco--fill:hover {
  box-shadow: 0 3px 00 #1a1446;
  -webkit-box-shadow: 0 3px 00 #1a1446;
}

.sco--blue-background {
  box-shadow: 0 3px 00 #565656;
  -webkit-box-shadow: 0 3px 00 #565656;
}

.sco--blue-background:hover {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  color: black;
  box-shadow: 0 3px 00 #565656;
  -webkit-box-shadow: 0 3px 00 #565656;
}

.sco-radio-group {
    label { 
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: 400;
        margin-bottom: 16px;

        &:hover {
            cursor: pointer;
        }

        .sco-radio-button {
            margin-right: 8px;
        }
    }
}

button.no-fill {
    background: none;
    border: none;
    padding: 0
}

.sco-radio-button {
    margin: 0;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;

    &:hover {
        cursor: pointer;
    }
}

.sco--noMarginBottom {
    margin-bottom: 0px;
}

@mixin button-small {
    .sco-button {
        line-height: 0;
    }
}

@mixin no-margin-top {
    margin-top: 0;
}

#salemove #sm-screen-reader {
    position: fixed !important;
}

.update-spinner-overlay {
    @include mobile-margin-offset;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 1000;
    justify-content: center;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    @media (max-width: $sco-sm) {
        width: calc(100% + 48px);
    }

    .update-spinner-container {
        @include card-style;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
    }
}

.sco-label.is-populated .sco-label-visual {
    top: 12px;
}

.sco-label-visual {
    line-height: 19px;
}

/* LMDS Overrides */

// All headings
.lm-Heading {
    margin-top: 0;
}

// Page headings
h1.lm-Heading2, h1.lm-Heading3 {
    margin-bottom: 18px;

    @include sco-breakpoint($sco-md) {
        position: relative;
        top: -40px;
        height: 0;
    }
}