@import "global-styles";

.link-paragraph-component {
    margin-bottom: 0px !important;
    .sco-textLink {
        display: inline;
    }

    span {
        color: $text-color;
    }  

    // overrides the boostrap hover scss
    a:hover {
        color: #06748c;
    }
}