@import 'global-styles';

.agent-title {
    font-weight: 400;
    font-size: .75rem;
    color: $text-color-light;
}

.agency-name {
    font-size: 1rem;
    font-weight: 400;
    color: $text-color;
}

.agent-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;

    app-icon {
        margin-right: 4px;
    }
}

.contact-method {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .phone {
        display: inline;
    }
    .email, .sms {
        margin-left: 24px;
        display: inline;
    
        app-icon {
            position: relative;
            top: 1px;
        }
    }

    .sms-display {
        display: flex;
        align-items: flex-start;

        .call-label, .text-label, app-icon {
            margin-right: 4px;
        }
    }

    .agent-mobile {
        display: inline;
        @include sco-breakpoint($sco-sm) {
            display: none;
        }
    }
    .agent-desktop {
        @include sco-breakpoint($sco-xs) {
            display: none;
        }
        @include sco-breakpoint($sco-sm) {
            display: inline;
        }
    }
}

