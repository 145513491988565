/* SPINNER */
@import "global-styles";

.overlay {
	position: fixed;
	background: white;
	border: 1px solid white;
	z-index: 5;
	height: 100%;
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	opacity: 0.9;
	/*animation: BG-color-fade 15s infinite linear;*/
}

.app-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .center {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	.spinner-blades {
		font-size: 50px;
		position: relative;
		display: inline-block;
		width: 1em;
		height: 1em;

		.spinner-blade {
			position: absolute;
			left: 0.4629em;
			bottom: 0;
			width: 0.15em;
			height: 0.15em;
			border-radius: 5em;
			background-color: transparent;
			transform-origin: center -0.2222em;
			animation: spinner-fade 1.2s infinite linear;
			animation-timing-function: ease-in-out;

			&:nth-child(1) {
				animation-delay: 0s;
				transform: rotate(0deg);
			}

			&:nth-child(2) {
				animation-delay: 0.15s;
				transform: rotate(45deg);
			}

			&:nth-child(3) {
				animation-delay: 0.3s;
				transform: rotate(90deg);
			}

			&:nth-child(4) {
				animation-delay: 0.45s;
				transform: rotate(135deg);
			}

			&:nth-child(5) {
				animation-delay: 0.6s;
				transform: rotate(180deg);
			}

			&:nth-child(6) {
				animation-delay: 0.75s;
				transform: rotate(225deg);
			}

			&:nth-child(7) {
				animation-delay: 0.9s;
				transform: rotate(270deg);
			}

			&:nth-child(8) {
				animation-delay: 1.05s;
				transform: rotate(315deg);
			}
		}
	}

	.spinner-label {
		font-size: 14px;
		text-align: center;
		margin-top: 10px;
		color: $text-color;
	}
}

@keyframes spinner-fade {
	0% {
		background-color: transparent;
	}

	25% {
		background-color: #0e66a8;
	}

	50% {
		background-color: #0e66a8;
	}

	100% {
		background-color: transparent;
	}
}

@keyframes BG-color-fade {
	0% {
		background-color: #ffffff;
	}

	45% {
		background-color: #ffffff;
	}

	55% {
		background-color: #000000;
	}

	95% {
		background-color: #000000;
	}

	100% {
		background-color: #ffffff;
	}
}
