/*
// Safeco MMA variables
*/
.sco-container {
	@include sco-grid-wrapper-container;

    // fixed maximum width
    max-width: 1052px;
    @media (min-width: 1136px) { // max-width + 42px margin left & right
        width: 100%;
        margin: 0 auto !important;
    }

    // adding 8px to margins to compensate for 24px from sco-grid
    @media (max-width: $sco-sm) {
        margin-left: 32px;
        margin-right: 32px;
    }
}


/*
// Bootstrap variable overrides
*/

$font-size-base: 1rem;
$font-family-base: 'Roboto', sans-serif;

// Extra small screen / phone
$screen-xs:                  320px !default;
$screen-xs-min:              $screen-xs !default;

// Small screen / tablet
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;

// Medium screen / desktop
$screen-md:                  1024px !default;
$screen-md-min:              $screen-md !default;

// Large screen / wide desktop
$screen-lg:                  1280px !default;
$screen-lg-min:              $screen-lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
