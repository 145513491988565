@import "@lmig/safeco-ui/scss/Form/Label";
@import "@lmig/safeco-ui/scss/Form/Select/Select";
@import "global-styles";

// Remove on SDS update
.sco-label.is-disabled {
    .sco-input-select {
        color: #707070 !important;
        border: solid 1px $color-border-light !important;
    }
}

.sco-label {
    &.useSmallBottomMargin {
        margin-bottom: 8px;
    }
}

.sco-label-select {
    margin: 0 0 26px 0;

    //only set is-error margin to 0 because there is no help text for select
    //isOptional is a custom class - spacing for optional text in self reg
    &.is-error, &.isOptional {
        margin: 0;
    }
}
