@import "global-styles";

.accordion-component {

    .accordion {
        @include sco-breakpoint($sco-md) {
			width: calc(100% + 32px);
            left: -16px;
		}
        width: 100%;
        position: relative;
        background: none;
        padding: 0;
        border-color: $color-border-light;
        border-style: solid;
        border-width: 1px 0 0 0;

        .accordion-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 20px 24px;

            .title {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                
                h3 {
                    font-weight: 600;
                    margin: 0;
                }
        
                app-icon {
                    margin-top: 4px;
                    margin-right: 16px;
                }
            }
    
            .expanded {
                svg {
                    transform: rotate( 180deg );            
                    transition: transform .5s ease;
                }
            }
            .collapsed {
                svg {
                    transform: rotate( 360deg );            
                    transition: transform .5s ease;
                }
            }
        }
    }

    .hide-border {
        border: none;
    }

    button {
        &:focus, .accordion-header {
            outline: none;
        }
    }
    button:focus > .accordion-header {
        outline: 5px auto -webkit-focus-ring-color;
        /* IE 10+ */
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            outline: 1px dotted grey;
        }
    }
    
    .accordion-content {
        -webkit-transition: height 0.5s ease-in-out;
        -moz-transition: height 0.5s ease-in-out;
        -ms-transition: height 0.5s ease-in-out;
        transition: height 0.5s ease-in-out;
        height: auto;
        overflow: hidden;

        @media (max-width: $sco-sm) {
            padding: 0 24px;
        }

        .accordion-expanded {
            margin-bottom: 48px;

            &.no-margin {
                margin-bottom: 0px;
            }
        }

    }

}
