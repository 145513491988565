@import "global-styles";
@import "unauth-styles";

// $screen-sm-min = 768px
// $screen-xs-max = 767px

#create-new-account-container {
  margin-bottom: 64px;

  .h1Spacing {
    margin-top: 32px;
    margin-bottom: 22px;

    @media (min-width: $screen-sm-min) {
      margin-top: 56px;
      margin-bottom: 32px;
    }
  }

  .header-alerts {
    background-color: #FFFFFF;
    margin-top: 16px;
    .sco-container {
      padding: 24px 0;
      
      @include sco-breakpoint($sco-md) {
        padding: 16px 0;
      }
    }
    

    .app-alerts {
      margin-bottom: 0;

      .banner-messages {
        margin-bottom: 0;
      }
    }
  }
  .mainTitle {
    @media screen and (max-width: $screen-xs-max) {
      font-size: $sco-h2-size;
      line-height: $sco-h2-line-height;
      letter-spacing: $sco-h2-spacing;
    }
  }
  .subTitle {
    font-size: 20px;
    font-weight: 300;

    @media screen and (max-width: $screen-xs-max) {
      font-size: 20px;
      margin-bottom: 16px;
    }
    @media (min-width: $screen-sm-min) {
      margin-bottom: 24px;
    }
  }

  .wayfinder-spacing ~ #create-new-heading-container .h1Spacing {
    @media (max-width: 768px) {
      margin-top: 0;
    }
  }

  #formInputContainer{
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-sm-min) {
      flex-direction: column;
      flex-wrap: wrap;
    }

    #formNameRow {
      display: flex;
      flex-direction: row;
      gap: 16px;
      
      app-input {
        flex-basis: 0;
        flex-grow: 1;

        @media (min-width: $screen-sm-min) {
          #text-error > span {
            white-space: nowrap; 
          }
        } 
      }
    }

    #formDOBEmailRow {
      display: flex;
      flex-direction: column;

      @media (min-width: $screen-sm-min) {
        flex-direction: row;
        gap: 16px;
      }

      app-input, app-mask-input {
        flex-basis: 0;
        flex-grow: 1;
      }
    }

    #formPolicyRow {
      @media (min-width: $screen-sm-min) {
        width: 50%;
        padding-right: 8px;
      }
    }
    #policyInput {
      display: flex;
     
      app-input {
        flex-basis: 0;
        flex-grow: 1;
      }
    }
  }

  #buttonContainer {
   margin-top: 32px;
  }

  #haveAccountSection {
    margin-top: 32px;
    a {
      display: inline;
    }
  }

  .wayfinder-spacing {
    @include wayfinder-spacing-style;
  }
}
