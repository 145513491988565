@import 'global-styles';

/* Shared css styles for the Account Unauth Pages */
//common spacing for h1
@mixin h1-unauth-spacing {
  //to make 40px above h1 and header bar
  margin-top: 48px;
  margin-bottom: 22px;
  //to make 32px above h1 and header bar
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 0px;
  }
  //to make 32px above h1 and header bar
  @media (max-width: 767px) {
    margin-top: 16px;
  }
}

//common spacing for h1 within white box
@mixin h1-unauth-spacing-white-box {
  text-align: center;
  top: 0;
  height: auto;
  //to make 40px above h1 and header bar
  margin-top: -8px;
  margin-bottom: -15px;
  //to make 32px above h1 and header bar
  @media (min-width: 321px) and (max-width: 1023px) {
    margin-top: -10px;
  }

  @media (max-width: 767px) {
    text-align: left;
  }
  //to make 32px above h1 and header bar
  @media (max-width: 320px) {
    margin-top: -11px;
  }
}

.first-heading {
  padding-top: 40px;
}


.input-spacing {
  margin-top: 8px;
}

@mixin unauth-content-style {
  @include card-style;
  padding-top: 20px;
  padding-bottom: 20px;
}

@mixin wayfinder-spacing-style {
  margin-top: -10px;
  margin-bottom: 5px;

  @media(min-width: $sco-sm) {
    margin-top: -20px;
  }
}

@mixin unauth-alert-before {
  margin-bottom: 45px;
}
@mixin unauth-alert-after {
  margin-top: -30px;
}
@mixin unauth-error-text {
  color: #d32f2f;
  font-weight: bold;
}

@mixin unauth-form {
  background: white;
  border: 1px solid #d8d8d9;
  margin: auto;
}

@mixin unauth-t-and-c {
  margin-bottom: 11px;
  padding-top: 18px;
}

@mixin unauth-button-container {
  text-align: center;
  width: 100%;
}

@mixin unauth-bottom-links {
  margin-top: 59px; /*to make it 64px*/
}

@mixin unauth-input-spacing {
  padding-top:22px;
}

@mixin unauth-last-input-button-spacing {
  padding-top: 8px; //match input fields' padding
}

.update-spinner-overlay {
  position: fixed;
  height: 100%;
  width: calc(100% + 32px);
  top: 0;
  display: flex;
  align-items: center;
  z-index: 1000;
  justify-content: center;
  left: 0;
  background: white;
  opacity: 0.8;
}
