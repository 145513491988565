@import "global-styles";

.billing-page-redesign {

	.modal-overlay-dark {
		position: fixed;
		background: rgba(0, 0, 0, 0.8);
		z-index: 999;
		height: 100%;
		width: 100%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
	  }

	.billing-tabs {
		display: block;
		margin: 24px 0;
	}

	.account-heading {
		margin-bottom: 4px;
	}

	.column-labels.account-heading {
		margin-bottom: 8px;
	}

	.column-labels {
		font-weight: 400;
		font-size: 14px;
		color: $text-color-light;
		margin-bottom: 0px;
	}

	.not-found-row {
		@include card-style;
		display: flex;
		flex-direction: column;

		.not-found-message {
			width: 100%;
			display: flex;
			align-items: center;

			.warning-icon-container {
				margin-right: 8px;
			}

			.not-found-message-container {
				display: flex;
				align-items: center;
				height: 100%;
				min-height: 35px;
			}
		}
	}

	.billing-account-row {
		@include card-style;
		padding-bottom: 0;
	}

	.billing-renewal-discount-display {
		display: flex;
		align-items: baseline;

		app-renewal-flag {
			margin-bottom: 16px;
		}

		.renewal-discount-message {
			font-size: .875rem;
			font-weight: bold;
			margin-left: 8px;
			a {							
				display: inline-block;
				font-size: .875rem;
				font-weight: bold;
			}
			margin-left: 16px;
		}

		@media (max-width: $sco-sm) {
			display: block;
			margin-bottom: 16px;

			.renewal-discount-message {
				margin-left: 0;
				margin-top: 8px;
			}	
		}	
	}

	.billing-account-row-display {
		margin-bottom: 32px;
		border-bottom-color: $color-border-light;
		border-bottom-width: 1px;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-flow: row wrap;
	}

	.billing-policy-column {
		order: 1;
		width: 100%;
		@include sco-breakpoint($sco-sm) {
			width: 30%;
		}	
	}

	.balance-column {
		display: none;
		@include sco-breakpoint($sco-sm) {
			width: 15%;
			order: 2;
			padding-left: 16px;
			display: inline-block;
		}
	}

	.payment-method-column {
		order: 3;
		width: 50%;
		padding-left: 8px;
		@include sco-breakpoint($sco-sm) {
			width: 20%;
			order: 3;
			padding-left: 16px;
		}
	}

	.payment-status-column {
		order: 2;
		padding-right: 8px;
		@include sco-breakpoint($sco-sm) {
			order: 4;
			width: auto;
			padding-right: 0;
			padding-left: 16px;
		}

		.renewal-billing {
			display: flex;
			flex-direction: row;
			align-items: center;
			font-size: .75rem;
			app-icon {
				margin: 2px 4px 0 0;
			}
		}
	}

	.reenter-payment-method {
		margin-bottom: 24px;
		
		.reenter-payment-method-link {
			font-size: .875rem;
		}
	}

	.billing-schedule-table {
		@include cam-table;

		.account-activity {
			width: 60%;
		}

		.recent-activity-label, .current-balance-disclaimer  {
			padding-bottom: 24px !important;

			&.padding-bottom-small {
				padding-bottom: 8px !important;
			}
		}

		.current-balance-disclaimer-row {
			.current-balance-disclaimer {
				.disclaimer-text {
					app-icon {
						margin-right: 2px;
					}
		
					span {
						font-size: 14px;
						color: $text-color-light;
					}
				}
			}
		}

		.balance-value {
			display: flex;
			flex-direction: row-reverse;
		}
	
		margin-bottom: 24px;
		background-color: transparent;
	}

	.account-number {
		margin-bottom: 6px;
	}

	div.billing-account-row.canceled-account {
		background-color: transparent;
		border: 1px solid $color-border-light;
		margin-bottom: 24px;

		.account-heading {
			color: #dc1637;
		}
	}

	.policy-display {
		display: flex;
		align-items: center;
		margin-top: 0px;
		margin-bottom: 8px;
		&:nth-last-of-type() {
			margin-bottom: 0px;
		}

		& span {
			font-weight: 600;
		}
	}

	.lob-icon {
		margin-right: 8px;
	}

	.table-row {
		display: table-row-group;
	}

	.small-dark {
		font-weight: 400;
		font-size: 16px;
	}

	.smaller-grey {
		font-size: 12px;
		font-weight: 400;
		color: $text-color-light;
	}

	.smaller-red {
		font-size: 12px;
		font-weight: 400;
		color: #dc1637;
	}

	.payment-amount {
		font-weight: 700;
		font-size: 20px;
	}

	.payment-amount-red {
		font-weight: 700;
		font-size: 20px;
		color: #dc1637;
	}


	.payment-actions-button-container {
		margin-top: 16px;
		display: none;
		@include sco-breakpoint($sco-sm) {
			display: block;
		}

		button {
			width: 100%;
		}
	}

	.mobile-payment-actions-button-container {
		display: none;
		margin: 8px 0 32px 0;

		@media (max-width: $sco-sm) {
			display: flex;
		}
	}

	.spinnerCard {
		width: 304px;
		height: 415px;
		display: inherit;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		margin-top: 32px;
		padding-top: 120px;
	}

	.feedback-container {
		margin: 0 auto;
	}

	.view-details-container {
		padding-top: 16px;
		border-top: 1px solid $color-border-light;
		margin-top: 16px;
	}

	.view-details {
		display: flex;
		flex-direction: column;
		align-items: left;
		justify-content: left;
		@include sco-breakpoint($sco-md) {
			padding: 0 16px;
		}

		a {
			display: flex;
			justify-content: space-between;
			font-weight: 700;
			font-size: 18px;
			color: $text-color;
		}
		svg {
			width: 10px;
			height: 10px;
		}
	}

	.expanded {
		svg {
			transform: rotate( 180deg );            
			transition: transform .6s ease;
		}
	}
	.collapsed {
		svg {
			transform: rotate( 360deg );            
			transition: transform .6s ease;
		}
	}

	.spacer {
		height: 16px;
	}

	.unformat-table-row {
		background-color: white !important;
		border: none !important;
	}

	.details-content {
		margin-top: 16px;
		margin-bottom: 32px;
		@include sco-breakpoint($sco-md) {
			padding: 0 8px;
		}

		.details-heading {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			span {
				font-size: 20px;
				font-weight: 300;
			}
		}
		.policy-breakdown {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			flex-flow: row wrap;
		}
		.breakdown-card {
			display: flex;
			flex-direction: column;
			flex: 0 1 100%;
			margin-top: 16px;
			border: 1px solid #eeeeee;
			padding: 8px;
			@include sco-breakpoint($sco-md-only) {
				flex: 0 1 49%;

				margin-left: 1%;
				margin-right: 1%;
				&:nth-of-type(2n+1) {
					margin-left: 0;
				}
				&:nth-of-type(2n) {
					margin-right: 0;
				}
			}
			@include sco-breakpoint($sco-md) {
				flex: 0 1 32%;

				margin-left: 1%;
				margin-right: 1%;
				&:nth-of-type(3n+1) {
					margin-left: 0;
				}
				&:nth-of-type(3n) {
					margin-right: 0;
				}
			}

			.card-row {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-left: 16px;
				padding-bottom: 8px;
			}
			a {
				display: inline;
			}
		}

		.statement-history-links {
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			.link {
				margin-top: 16px;
			}
			
		}
	}

	.delay-payment-link {
		display: inline-block;
		font-size: .75rem;
    cursor: pointer;

		&.sco-textLink.sco--standalone::after {
			margin-left: 4px;
		}
	}

	.scheduled-payments {
		color: $color-information-text;
		font-size: 12px;
		font-weight: 600;
		margin: -16px 0 24px 0;
		display: flex;

		&.failed-payment {
			color: $color-error-text;
		}

		.scheduled-payment-message-text {
			margin-left: 4px;
		}

		.schedule-make-payment-cancel {
			display: inline-block;
			margin-left: 6px;
			font-size: .75rem;
	
			&.sco-textLink.sco--standalone::after {
				margin-left: 4px;
			}
		}

		.schedule-make-payment-cancel {
			font-size: .75rem !important;
		}
	}
	
	@media screen and (max-width: ($screen-sm - 1)) {
		div.billing-account-row.canceled-account {
			background-color: transparent;
			border: 1px solid $color-border-light;
			margin-bottom: 24px;

			.account-heading {
				color: #dc1637;
			}
		}

		.large-grey {
			padding-top: 0px;
		}
	}

	.hide-extra-small {
		@media screen and (max-width: ($screen-sm - 1)) {
			display: none;
		}
	}

	.show-extra-small {
		@include sco-breakpoint($sco-sm) {
			display: none;
		}
	}
}
