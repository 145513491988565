@import "global-styles";

.app-alerts {
  @include mobile-margin-offset;
    margin-bottom: 24px;
}

.banner-messages {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 3px;
  margin-bottom: 8px;
  background-color: white;
  border: 1px solid $color-border-light;

  .negative-type &, .alert-type & {
    border: 2px solid $color-error-border;
    // border-left: 6px solid $color-error-border;
    .icon {
      background-color: $color-error-border;
    }
  }

  .caution-type & {
    border: 2px solid $color-warning-border;
    // border-left: 6px solid $color-warning-border;
    .icon {
      background-color: $color-warning-border;
    }
  }

  .informative-type & {
    border: 2px solid $color-information-border;
    // border-left: 6px solid $color-information-border;
    .icon {
      background-color: $color-information-border;
    }
  }

  .positive-type & {
    border: 2px solid $color-success-border;
    // border-left: 6px solid $color-success-border;
    .icon {
      background-color: $color-success-border;
    }
  }

  .message-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: white;

    .icon {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 12px 13px 0 11px;
      min-width: 40px;

      app-icon {
        height: 1rem;
        width: 1rem;
      }
    }

    .message-content {
      padding: 12px 16px 12px 8px;

      .message-text {
        color: $text-color;

        .text-title {
          font-weight: 700;
          color: $text-color;
          /* Next version of alerts from design system */
          // .error-type &, .alert-type  & {
          //     color: $color-error-border;
          // }
          // .warning-type & {
          //     color: $color-warning-border;
          // }
          // .information-type & {
          //     color: $color-information-border;
          // }
          // .success-type & {
          //     color: $color-success-border;
          // }
        }

        app-link-paragraph {
          display: inline;

          p {
            display: inline;
            margin: 0;
          }
        }

        span {
          font-weight: 400;
        }
      }

      .sco-link {
        display: inline;
        color: $color-teal-dark;
      }
    }
  }

  .dismiss-notification {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-right: 1rem;
    padding-top: 13px;
    background-color: white;

    app-icon {
      height: 1rem;
    }
  }


  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .message-text {
      padding-left: 20px;
    }
  }
}

.banner-messages.negative-type {
  background-color: $color-error-border;
}
.banner-messages.caution-type {
  background-color: $color-warning-border;
}
.banner-messages.informative-type {
  background-color: $color-information-border;
}
.banner-messages.positive-type {
  background-color: $color-success-border;
}

.banner-messages.blue-title {
  .message-box .message-content .message-text .text-title {
    color: $color-information-text;
  }
}


//Overwriting bootstrap
.alert {
  padding: 0px;
  margin-bottom: 0px;
}

.lmig-Notification-content {
  flex-wrap: initial !important;
}

.title {
  font-weight: 700;

  & .negative, & .alert {             
          color: $color-error-text;
  }
  & .neutral {
      color: $text-color;
  }
  & .caution {
      color: $color-warning-text;
  }
  & .informative {
      color: $color-information-text;
  }
  & .positive {
      color: $color-success-text;
  }
}