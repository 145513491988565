@import "@lmig/safeco-ui/scss/Icon/Icon";

.sco-icon {
    svg {
        vertical-align: baseline;

        // manually set icon colors to align with current LMDS libraries
        // temporary until we switch icons to LMDS angular icon component
        &.lmig-Icon--color-default { fill:  #1A1446; };
        &.lmig-Icon--color-inverse { fill: #FFFFFF };
        &.lmig-Icon--color-positive { fill: #008040 };
        &.lmig-Icon--color-negative { fill: #D32F2F };
        &.lmig-Icon--color-informative { fill: #0061F5 };
        &.lmig-Icon--color-caution { fill: #B85D00 };
        &.lmig-Icon--color-gray { fill: #343741 };
        &.lmig-Icon--color-active { fill: #28A3AF };
        &.lmig-Icon--color-teal { fill: #06748C };
        &.lmig-Icon--color-disabled {fill: #707070 };
    }
    
    .sco-icon--teal {
        color: #06748C; // link icon color
    }
}

