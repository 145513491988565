@import "global-styles";
.feedback-component {
  width: 300px;

  .feedback-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .heading {
      font-weight: 300;
      font-size: 18px;
      color: $text-color;
      display: block;
      width: 350px;
    }

    .reactions {
      display: block;

      .reaction-group {
        text-align: center;
        display: inline;
        margin-right: 16px;
        border: none;
        background: none;
        &:last-child {
          margin-right: 0px;
        }
      
        &:hover {
          cursor: pointer;
        }
    
        img {
          width: 28px;
          height: 28px;
        }
    
        .response {
          line-height: 36px;
          display:inline;
          padding-left: 3px;
          vertical-align: middle;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .submit-container {
      padding-top: 16px;

      textarea {
        resize: none;
        width: 300px;
        height: 80px;
        border-radius: 4px;
        padding: 4px;
    
        &::placeholder {
          color: $text-color-light;
          font-weight: 500;
        }
      }
      
      .sco-button-group {
        button {
          margin-top: 8px;
        }
      }
    }

    .success-icon-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: white;
      padding: 16px;
      margin-top: 16px;

      app-icon {
        margin-right: 16px;
      }
    }

    .issue-icon-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: white;
      padding: 16px;
      margin-top: 16px;

      .contact-message {
        margin-bottom: 16px;
      }
    }

  }

}
