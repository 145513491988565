@import "global-styles";
@import "unauth-styles";

.app-root {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #343741;
  background-color: #ffffff;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -ms-height: 100px;

  .header-alerts {
    background-color: #FFFFFF;

    .sco-container {
      padding: 24px 0;
      
      @include sco-breakpoint($sco-md) {
        padding: 16px 0;
      }
    }
    

    .app-alerts {
      margin-bottom: 0;

      .banner-messages {
        margin-bottom: 0;
      }
    }
  }

  .content {
    //flex: 1 0 auto;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;

    .alerts {
      display: block;
    }

    .agent-card {
      display: none;    

      &.show-card-sms {
        @media (max-width: $sco-md) {
          display: block;
          margin-bottom: 24px;
        }

        @media (min-width: $sco-md) {
          display: flex;
          justify-content: flex-end;
          min-height: 67px;
        }
        
      }

      @include sco-breakpoint($sco-md) {
        display: flex;
        justify-content: flex-end;
        min-height: 67px;
      }
    }
  }

  .mobile-app {
    margin-top: 16px;
  }

  .header-container {
    background-color: $color-white;
    padding-top: 16px;
    margin-bottom: 24px;
    border-bottom: 1px solid #d8d8d9;

    &.no-menu {
      padding-bottom: 16px;

      .app-mega-menu {
        height: 0 !important;
      }

      @media screen and (min-width: 768px) {
        &.no-bottom-margin {
          margin-bottom: 0 !important;
        }
      }
    }

    .header-top {
      display: flex;
      margin-bottom: 16px;
      min-height: 42px;

      .safeco-logo {
        width: 175px;
        height: 43px;
      }

      .header-info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .welcome {
          padding-left: 16px;
          padding-right: 8px;
          display: none;
          @include sco-breakpoint($sco-sm) {
            display: block;
          }
        }

        .menu-link {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          @include sco-breakpoint($sco-sm) {
            flex-direction: row;
            align-items: center;
            min-width: 388px;
          }

          a {
            font-size: .875em;
            display: inline-block;
            text-align: center;
            cursor: pointer;
          }

          .logout {
            padding: 0 8px 0px 8px;

            @include sco-breakpoint($sco-sm) {
              padding: 0;
            }

            app-icon {
              margin-right: 4px;
            }
          }

          .pipe {
            display: none;
            padding: 0 .75rem;
            color: $color-border-light;

            @include sco-breakpoint($sco-sm) {
              display: inline-block;
            }
          }

          .help-icon {
            padding: 8px 8px 0 8px;

            @include sco-breakpoint($sco-sm) {
              padding: 0;
            }

            app-icon {
              margin-right: 4px;
            }
          }

          app-search {
            display: none;

            @include sco-breakpoint($sco-md) {
              display: flex;
            }
          }

          .menu-icon {
            height: 18px;
            width: 18px;
            margin-bottom: 5px;
          }
        }
      }
    }

    .full-width-bar {
      width: 100%;
      margin-top: 13px;
      background-color: $color-primary;
    }

    .app-mega-menu.sco-container {
      height: 44px;

      @media screen and (max-width: $screen-sm-max) {
        height: 60px;
      }

      @media screen and (max-width: $screen-xs-max) {
        margin: 0;
      }
    }

    .overlay {
      z-index: 1000;
      background: black;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      visibility: hidden;
      opacity: .8;
      transition: visibility .4s ease-in-out;
    }

    .overlay.active {
      transition: visibility .4s ease-in-out;
      visibility: visible;
    }

    .right-rail {
      position: fixed;
      background: #f7f7f7;
      min-width: 310px;
      width: 25%;
      height: 100%;
      max-height: 100vh;
      z-index: 1000;
      right: -300px;
      top: 0;
      padding: 20px;
      visibility: hidden;
      transition: all 0.4s linear;
      overflow-y: auto;
    }

    .right-rail.active {
      right: 0;
      transition: all 0.4s linear;
      visibility: visible;
    }

    .search-rail {
      position: fixed;
      background: #f7f7f7;
      min-width: 200px;
      width: 350px;
      height: 250px;

      @media screen and (max-width: $screen-sm-max) {
        width: 85%;
        margin: 0 auto;
      }
      // max-height: 100vh;
      z-index: 1000;
      right: -350px;
      top: 50;
      padding: 20px;
      visibility: hidden;
      transition: all 0.4s linear;
      overflow-y: auto;
    }

    .search-rail.active {
      @media screen and (max-width: $screen-sm-max) {
        margin-right: auto;
        margin-left: auto;
      }

      right: 30px;
      border: 1px solid #c0bfc0;
      transition: all 0.4s linear;
      visibility: visible;
    }

    .text-link-close {
      float: right;

      &:hover {
        cursor: pointer;
      }
    }

    .help-links {
      border-bottom: 1px dotted #b0b1b2;
      padding: 10px 5px;
    }
  }

  .main-content {
    display: block;
    width: 100%;

    @include sco-breakpoint($sco-md) {
      display: block; /*inline-block*/
      padding-left: 56px;
      padding-right: 56px;
    }
  }

  .shrink-view {
    @include sco-breakpoint($sco-md) {
      width: calc(100% - 300px);
    }
  }

  .right-rail-col {
    display: block;
    float: right;
    margin-right: -3px;
  }

  .welcome-sm {
    color: #FFFFFF;
    background-color: #002663;
    height: 45px;
    margin-top: -24px;
    margin-bottom: 8px;
    padding: 12px 0 12px 24px;
    letter-spacing: 0;
    line-height: 21px;
    @include sco-breakpoint($sco-sm) {
      display: none;
    }

    &.no-bottom-margin {
      margin-bottom: 0 !important;
    }
  }

  .alerts {
    // margin-bottom: 24px;
    
    @include sco-breakpoint($sco-md) {
      padding: 0 56px;
    }
  }

  .plus {
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
  }

  #no-cookies {
    .main-content {
      display: block;
      width: 100%;
  
      @include sco-breakpoint($sco-md) {
        display: block; /*inline-block*/
        padding-left: 56px;
        padding-right: 56px;
      }
    }
    
    .content {
      background-color: #ffffff;
      border: 1px solid #d8d8d9;
      border-radius: 2px;
      padding: 20px;
      margin-top: 25px;
    }

    .h1Spacing {
      @include h1-unauth-spacing;
    }

    .error-icon {
      font-size: 16px;
    }
  }

  .footer-container {
    margin-top: 4rem;

    &.no-top-margin {
      margin-top: 0 !important;
    }
  }

}
