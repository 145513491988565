@import "global-styles";

.esign-parent-container {
  max-width: 100% !important;
  flex-direction: column;
  display: flex;


  .esign-card-container {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    text-align: left;

    @media only screen and (max-width: $sco-sm) {
      background-color: #F5F5F5;
    }

    @media only screen and (min-width: $sco-sm) {
      margin: 0 0px;
    }

    p {
      margin: 0;
    }

    .error-alert {
      display: flex;
      padding: 8px;
      flex-direction: row;
    }

    .esign-policy-card {
      flex-direction: column;
      color: $text-color;
      background-color: white;
      border: 1px solid $color-border-light;
      width: 660px !important;
      padding: 16px 16px 16px 16px;
      margin-bottom: 32px;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0;

      @media only screen and (min-width: $sco-md) {}

      @media only screen and (max-width: $sco-sm) {
        width: 100% !important;
      }

      @media only screen and (max-width: $sco-sm) {
        margin-bottom: 24px;
        border: none;
      }

      .policy-type-and-number {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        flex-direction: column;
        padding-bottom: 4px;

        .heading-container {
          display: flex;
          align-items: flex-start;
          flex-direction: row;
          justify-content: center;

          .icon {
            margin-right: 4px;
            margin-bottom: 16px;

            .sco-icon-background {
              height: 36px;
              width: 56px;
            }
          }

          .policy-nickname {
            color: $text-color;
            font-size: 24px;
            text-align: center;
            line-height: .9cm;
            letter-spacing: 0;
            font-weight: 700;
            overflow-wrap: break-word;
            margin-bottom: 20px;

            @media (max-width: $sco-sm) {
              width: 100%;
              text-align: left;
            }

            p {
              margin: 0;
            }

            .lmig-Popover--iconAndText .lm-IconButton {
              top: 14px;
            }

            .lmig-Popover--iconAndText {
              text-align: left !important;
              font-weight: initial !important;
            }

            .lmig-Popover {
              margin-top: -16px;
            }
          }
        }
      }

      .signer-section-dual {
        display: flex;
        justify-content: space-between;
        padding-bottom: 24px;

        @media only screen and (max-width: $sco-sm) {
          flex-wrap: wrap;

          .lmds-button-dynamic-width {
            margin-top: 8px;
            width: 100%;
          }
        }
      }

      .signer-section-dual:nth-child(even) {
        border-bottom: 1px solid $color-border-light;
      }

      .signer-section-dual:last-child {
        padding-top: 24px;
      }

      .signer-section {
        display: flex;
        justify-content: space-between;
        padding-bottom: 24px;

        @media only screen and (max-width: $sco-sm) {
          flex-wrap: wrap;

          .lmds-button-dynamic-width {
            margin-top: 8px;
            width: 100%;
          }
        }
      }

      .customer-name {
        display: flex;
        align-items: flex-end;
        padding-left: 8px;
        padding-bottom: 8px;
        overflow-wrap: break-word;
      }

      .lmig-Button {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
      }

      .lmig-Badge .lmig-Badge-background {
        padding: 8px 16px;
      }

      .lmds-badge {
        margin-top: 8px;
      }

      .error-card-body {
        flex-wrap: wrap;
        text-indent: -32px;
        padding-left: 44px;
        padding-bottom: 8px;

        .lmig-Alert {
          display: inline;
          font-size: 16px;
          padding-left: 3px;
        }

        .regular-text {
          word-wrap: break-word;
        }

        .phone-link {
          font-size: 16px;
          text-decoration: underline;
        }

        .lm-IconWrapper {
          vertical-align: text-top;
        }
      }

    }
  }
}