html:not([id]) > body {
    height: auto;
    margin: 0px;
}

/* HYDRA CREDIT CARD IFRAME CSS */

#component-container {
    margin: 8px;
}

/* Buttons */
.adyen-checkout__button,
.adyen-checkout--pay {
    /* Pay button */
    float: right;
    border-color: #4399a8;
    background-color: #99e5ea;
    font-family: "Roboto", sans-serif;
    height: 50px;
    color: #1a1446;
    width: auto;
    text-align: center;
    font-size: 16px !important;
    font-weight: 700;
    line-height: 16px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    letter-spacing: 0.0625rem;
    margin-top: 0 !important;
    box-shadow: 0 3px 00 #06748C;
    -webkit-box-shadow: 0 3px 00 #06748C;
}

.adyen-checkout__button img,
.adyen-checkout--pay img,
.adyen-checkout__icon,
.adyen-checkout-input__inline-validation--invalid,
.adyen-checkout__card__brands,
.adyen-checkout__fieldset--billingAddress > .adyen-checkout__fieldset__title {
    display: none;
}

.adyen-checkout__button:hover,
.adyen-checkout--pay:hover {
    background-color: #4399a8;
    color: white;
    box-shadow: 0 3px 00 #1A1446;
    -webkit-box-shadow: 0 3px 00 #1A1446;
}

.adyen-checkout__button:active,
.adyen-checkout--pay:active {
    background-color: #4399a8;
    box-shadow: 0 3px 00 #1A1446;
    -webkit-box-shadow: 0 3px 00 #1A1446;
}

.adyen-checkout__button:focus,
.adyen-checkout--pay:focus {
    border: solid 2px black;
}

@media (max-width: 602px) {
    .adyen-checkout__button,
    .adyen-checkout--pay {
        width: 100%;
    }
}

@media (max-width: 602px) {
    .adyen-checkout__field-wrapper {
        display: block;
    }
    .adyen-checkout__field--expiryDate {
        width: 100%;
        margin-right: 0;
        margin-bottom: 32px !important;
    }
    .adyen-checkout__field--securityCode {
        width: 100%;
        margin-left: 0 !important;
        margin-bottom: 32px !important;
    }
    .adyen-checkout__card__holderName {
        width: 100% !important;
        display: block;
    }
    .adyen-checkout__fieldset--billingAddress {
        width: 100% !important;
        display: block !important;
        padding-left: 0 !important;
        position: initial !important;
    }

}

.adyen-checkout__field--expiryDate span.adyen-checkout__label__text::after {
    content: " (MM/YY)";
}

.adyen-checkout__field--expiryDate,
.adyen-checkout__field--securityCode {
    margin-bottom: 24px;
}

.adyen-checkout__field--error {
    margin-bottom: 0px !important;
}

.adyen-checkout__field--error-wrapper {
    margin-bottom: 0px !important;
}

.adyen-checkout__field--error label {
    margin-bottom: 3px;
}

.adyen-checkout__card__holderName {
    margin-top: 0;
    width: 66%;
    display: inline-block;
}

.adyen-checkout__fieldset--billingAddress {
    margin-top: 0 !important;
    padding-left: 16px;
    width: 33%;
    display: inline-block;
    position: fixed;
}

.adyen-checkout__label {
    margin-bottom: 32px;
}

.adyen-checkout__label--focused span.adyen-checkout__label__text,
.adyen-checkout__label:focus-within span.adyen-checkout__label__text {
    color: #565656 !important;
    line-height: 16px;
}

.adyen-checkout__label__text {
    /* Text element inside the form label container */
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #565656 !important;
    line-height: 16px;
}

.adyen-checkout__input {
    /* Input fields */
    font-family: "Roboto", sans-serif !important;
    font-size: 16px !important;
    color: #343741;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    margin: 0;
    padding: 14px 10px 15px;
    font-size: 16px;
    border-color: #707070;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    transition: all 0.1s ease-in-out;
    background-color: white;
    border-radius: 3px;
    text-overflow: "";
    width: 100%;
    min-height: 50px;
    cursor: pointer;
    caret-color: #707070;
    display: block;
    position: relative;
}

.adyen-checkout__input:hover {
    border-color: #28a3af;
}

.adyen-checkout__input--focus,
.adyen-checkout__input:focus {
    border: 2px solid black !important;
    box-shadow: none !important;
}

.adyen-checkout__input--error,
.adyen-checkout__input--invalid {
    /* Error state for the input fields */
    background-color: #fff4f5 !important;
    border-color: #d32f2f;
}

.adyen-checkout__input--error:hover,
.adyen-checkout__input--invalid:hover {
    border-color: #707070;
}

.adyen-checkout__error-text {
    /* Error message text */
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    margin-top: 5px;
    height: 24px;
}

.adyen-checkout__error-text::before {
    content: url("data:image/svg+xml;utf8,<svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 20' aria-label='Error Alert' class='lmig-Icon--color-error'><g transform='translate(-2,4)'><path style='fill:%23d32f2f' d='M8.0007,0.0044 C12.4087,0.0044 15.9947,3.5914 15.9947,8.0004 C15.9947,12.4084 12.4087,15.9954 8.0007,15.9954 C5.8677,15.9954 3.8607,15.1634 2.3497,13.6514 C0.8377,12.1394 0.0047,10.1324 0.0047,8.0004 C0.0047,3.5914 3.5917,0.0044 8.0007,0.0044 Z M8.0007,1.0044 C4.1437,1.0044 1.0047,4.1424 1.0047,8.0004 C1.0047,11.8574 4.1437,14.9954 8.0007,14.9954 C11.8577,14.9954 14.9947,11.8574 14.9947,8.0004 C14.9947,4.1424 11.8577,1.0044 8.0007,1.0044 Z M8.52,11.077 L8.52,12.139 L7.468,12.139 L7.468,11.077 L8.52,11.077 Z M8.5097,3.8618 L8.3947,10.1528 L7.5737,10.1528 L7.4577,3.8618 L8.5097,3.8618 Z'></path></g><rect width='100%' height='100%' style='opacity: 0;'><title>Error Alert</title></rect></svg>");
}

/* --------------------------- HYDRA EFT IFRAME CSS --------------------------- */

/* Pay button */
#eftSubmit {
    float: right;
    padding: 15px;
    border-color: #4399a8;
    background-color: #99e5ea;
    font-family: "Roboto", sans-serif;
    height: 49px;
    margin-right: 1px;
    margin-bottom: 8px;
    color: #1a1446;
    width: auto;
    text-align: center;
    font-size: 16px !important;
    font-weight: 700;
    line-height: 16px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    letter-spacing: 0.0625rem;
    margin-top: 0 !important;
    box-shadow: 0 3px 00 #06748C;
    -webkit-box-shadow: 0 3px 00 #06748C;
    cursor: pointer;
}

#eftSubmit:hover {
    background-color: #4399a8;
    color: white;
    box-shadow: 0 3px 00 #1A1446;
    -webkit-box-shadow: 0 3px 00 #1A1446;
}

#eftSubmit:active {
    background-color: #4399a8;
    box-shadow: 0 3px 00 #1A1446;
    -webkit-box-shadow: 0 3px 00 #1A1446;
}

#eftSubmit:focus {
    border: solid 2px black;
}

#eftCancel {
    display: none;
}

.styleHostedPaymentFormRow:last-of-type {
    height: auto;
}

.submitContainer {
    margin-top: 8px;
    height: 52px;
}

@media (max-width: 615px) {
    #eftSubmit {
        width: 99.8%;
    }
}

/* Inputs */
.styleNameOnAccountLabel,
.styleAccountTypeLabel,
.styleRoutingNumberLabel,
.styleAccountNumberLabel,
.styleZipCodeLabel {
    /* Text element inside the form label container */
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #565656 !important;
    line-height: 16px;
}

.styleHostedPaymentFormRow {
    margin-bottom: 0;
    padding-right: 8px;
    min-height: 110px;
}

.styleHostedPaymentFormRow:nth-of-type(3),
.styleHostedPaymentFormRow:nth-of-type(4) {
    /* Routing number and account number rows */
   margin-bottom: 0;
}

.styleHostedPaymentFormRow:last-of-type {
    min-height: auto;
}

.styleHostedPaymentFormRow > label {
    width: 100%;
}

.styleHostedPaymentFormRow > label > input,
.styleHostedPaymentFormRow > label > select {
    font-family: "Roboto", sans-serif !important;
    font-size: 16px !important;
    color: #343741;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 14px 10px;
    appearance: none;
    box-sizing: border-box;
    margin: 0;
    padding: 14px 10px 15px;
    font-size: 16px;
    border-color: #707070;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    transition: all 0.1s ease-in-out;
    background-color: white;
    border-radius: 3px;
    text-overflow: "";
    width: 99.9%;
    min-height: 50px;
    cursor: pointer;
    caret-color: #707070;
    display: block;
    position: relative;
    border: 1px solid #707070;
    height: 40px;
}

.styleHostedPaymentFormRow > label > input:hover,
.styleHostedPaymentFormRow > label > select:hover {
    border-color: #28a3af;
}

#accountType {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
    padding: 14px 10px 13px 5px;
}

#accountType:focus {
    border: solid 2px black;
}

#routingResult {
    font-size: 12px;
    padding-top: 4px;
    font-family: "Roboto", sans-serif !important;
}

/* Errors */
input.styleErrorMessage {
    background-color: #fff4f5 !important;
    border-color: #d32f2f !important;
}

label.styleErrorMessage {
    color: #d32f2f;
    font-size: 12px;
    padding-top: 4px;
    font-family: "Roboto", sans-serif !important;
}


.styleErrorMessage::before {
    content: url("data:image/svg+xml;utf8,<svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' aria-label='Error Alert' class='lmig-Icon--color-error' style='margin-top: 4px; margin-right: 4px'><g><path style='fill:%23d32f2f' d='M8.0007,0.0044 C12.4087,0.0044 15.9947,3.5914 15.9947,8.0004 C15.9947,12.4084 12.4087,15.9954 8.0007,15.9954 C5.8677,15.9954 3.8607,15.1634 2.3497,13.6514 C0.8377,12.1394 0.0047,10.1324 0.0047,8.0004 C0.0047,3.5914 3.5917,0.0044 8.0007,0.0044 Z M8.0007,1.0044 C4.1437,1.0044 1.0047,4.1424 1.0047,8.0004 C1.0047,11.8574 4.1437,14.9954 8.0007,14.9954 C11.8577,14.9954 14.9947,11.8574 14.9947,8.0004 C14.9947,4.1424 11.8577,1.0044 8.0007,1.0044 Z M8.52,11.077 L8.52,12.139 L7.468,12.139 L7.468,11.077 L8.52,11.077 Z M8.5097,3.8618 L8.3947,10.1528 L7.5737,10.1528 L7.4577,3.8618 L8.5097,3.8618 Z'></path></g><rect width='100%' height='100%' style='opacity: 0;'><title>Error Alert</title></rect></svg>");
}