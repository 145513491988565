@import "global-styles";

#esign-status {

  .center {
    text-align: center;
  }

  .policy-cards-center {
    text-align: start;
  }

  button {
    display: block;
  }

  .header-and-cards{
    max-width: 690px !important;
  }

  #policy-cards-section {
    padding-top: 24px;
    margin-top: 24px;
    margin: 0 auto;
    
    @media only screen and (max-width: $sco-sm) {
      margin-left: -33px;
      margin-right: -33px;
      background-color: #F5F5F5;
    }
  }

  .centerCards {
    display: inline;
  }

  .spinnerCard {
    background-color: white;
    width: 304px;
    height: 396px;
    display: inherit;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 32px;
    padding-top: 100px;

    @media only screen and (min-width: 768px) {
      width: 360px;
    }

    & app-spinner {
      margin: 0 auto;
    }
  }

}