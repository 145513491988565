@import "global-styles";

.landing-component {
    display: flex;
    flex-direction: column;

    .esign {
        order: 1;
    }

    .discounts {
        order: 1;
    }

    .underline {
        text-decoration:underline;
    }

    .desktop {
        margin-top: 0;
        
        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            agent-card {
                margin-bottom: 8px;
            }
        }

        #customer-greeting {
            margin-bottom: 32px;
        }
        

        .tiled-container {
            order: 3;
            display: flex;
            @include sco-breakpoint($sco-sm - 1) {
                flex-direction: column;
            }
            @include sco-breakpoint($sco-md) {
                flex-direction: row;
                justify-content: space-between;
            }
    
            .billing {
                // margin-bottom: 24px;
                order: 3;
                @include sco-breakpoint($sco-sm - 1) {
                    width: 100%
                }
                @include sco-breakpoint($sco-md) {
                    width: calc(100% - 324px);
                }
            }
    
            .info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                order: 4;
                @include sco-breakpoint($sco-md) {
                    flex-direction: column;
                    justify-content: flex-start;
                }
    
                .claims {
                    order: 5;
                    width: 49%;
    
                    @include sco-breakpoint($sco-md) {
                        order: 5;
                        width: 300px;
                    }
                }
                .info-card {
                    order: 6;
                    width: 49%;
    
                    @include sco-breakpoint($sco-md) {
                        order: 4;
                        width: 300px;
                    }
                }
            }
        }

        .new-business-tiled-container {
            display: flex;
            justify-content: space-between;

            .new-business {
                width: 32%;
            }
        }
    
        .tile {
            width: 100%;
            // margin-bottom: 24px;
        }
        
        .policies {
            order: 4;
            @include sco-breakpoint($sco-sm - 1) {
                order: 6;
            }
        }
    }    
    
    .mobile {
        display: flex;
        flex-direction: column;

        app-agent-card {
            display: block;
            margin-bottom: 24px;
        }
        .billing {
            order: 3;
        }
        .policies {
            order: 4;
        }
        .claims {
            order: 5
        }
        .info-card {
            order: 6;
        }
    }

    .has-claim {
        order: 2 !important;
    }
}
