@import "global-styles";
.paperless-options-page {

    .paperless-descriptor {
        margin-bottom: 24px;
        padding-right: 24px;
        font-weight: 600;
    }

    .spinner-card {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        align-items: center;
    }

    .modal-overlay-dark {
        position: fixed;
        background: rgba(0, 0, 0, 0.8);
        z-index: 999;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }    
    
    .paperless-options-container {
        position: relative;
        margin-bottom: 24px;

        .paperless-page-descriptor {
            margin-bottom: 24px;
            display: flex;
            flex-direction: row;
            max-width: 100%;
            @include sco-breakpoint($sco-sm) {
                max-width: 705px;
            }
        }

        .email-container {
            display: flex;
            flex-direction: column;
            padding-top: 1rem;
            padding-bottom: 1rem;
            margin-bottom: 1.5rem;
            border-bottom: 1px solid $color-border-light;

            h2 {
                margin-bottom: 0px;
                font-size: 1rem;
            }

            a {
                display: flex;
                align-items: center;
            }
        }

        .paperless-action {
            margin-bottom: 1.5rem;
            padding-bottom: 1.5rem;
            border-bottom: 1px solid $color-border-light;

            .paperless-action-content {
                max-width: 100%;
                @include sco-breakpoint($sco-sm) {
                    max-width: 705px;
                }
            }

            .card-title {
                display: flex;
                align-items: center;
                margin-bottom: 1.5rem;
                
                h2 {
                    font-size: 1.5rem;
                    font-weight: 300;
                    margin-bottom: 0px;
                    margin-right: 2rem;
                }

                .no-margin {
                    margin-bottom: 0;
                }
            }

            .action-container {
                display: flex;
                justify-content: space-between;

                p {
                    margin-bottom: .5rem;
                }
            }

            .inprogress-message {
                display: flex;
                
                .info-icon {
                    margin-right: 8px;
                }
            }

            .ineligible-text {
                font-size: .75rem;
                margin-top: 16px;
            }

            .turned-off-descriptor {
                margin-top: 1rem;
                margin-bottom: 1.5rem;
            }

            .confirm-container {
                .terms-and-conditions-container {
                    margin-bottom: 24px;
                }
            }
        }
    }

    .paperless-options-list {
        .paperless-option {
            @include card-style;
            min-height: 102px;
            .policy-title-container {
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                .policy-info-container {
                    margin-left: 8px;
                    .policy-nickname {
                        margin: 0;
                    }
                }
            }
            .paperless-info-container {
                display: flex;
                flex-direction: row;
                @media (max-width: $sco-sm) {
                    flex-direction: column;
                }
                .paperless-container {
                    width: 50%;
                    @media (max-width: $sco-sm) {
                        width: 100%;
                    }
                }
                .paperless-info-text {
                    margin-top: 16px;
                    margin-bottom: 16px;
                }
                .paperless-container {
                    border-left: 1px dotted $color-border-light;
                    border-top: none;
                    margin-top: 0px;
                    padding-top: 0px;
                    padding-left: 16px;
                    @media (max-width: $sco-sm) {
                        border-left: none;
                        border-top: 1px dotted $color-border-light;
                        margin-top: 16px;
                        padding-top: 16px;
                        padding-left: 0px;
                    }
                    .paperless-info {
                        margin-left: 40px;
                        display: flex;
                        flex-direction: row;
                        @media (max-width: $sco-sm) {
                            flex-direction: column;
                        }
                        .email-edit {
                            margin-right: 8px;
                        }
                    }
                    .paperless-control {
                        margin-left: 40px;
                        margin-top: 8px;
                        .paperless-radio-buttons {
                            display: flex;
                            flex-direction: row;
                            @media (max-width: $sco-sm) {
                                flex-direction: column;
                            }
                            .doc-type-radio-button {
                                display: flex;
                                align-items: center;
                                &:last-child {
                                    margin-left: 48px;
                                    @media (max-width: $sco-sm) {
                                        margin-left: 0px;
                                        margin-top: 16px;
                                    }
                                }
                                &:hover {
                                    cursor: pointer;
                                }
                                input {
                                    margin: 0;
                                    height: 20px;
                                    width: 20px;
                                }
                                label {
                                    margin-bottom: 0px;
                                    margin-left: 8px;
                                    font-weight: normal;
                                    color: $text-color;
                                }
                                input,
                                label {
                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
                .paperless-toggle-heading {
                    display: flex;
                    align-items: center;
                    .paperless-toggle-title {
                        margin: 0;
                    }
                    .paperless-title-no-icon {
                        margin-left: 24px;
                    }
                }
                .billing-container {
                    border-left: none;
                    padding-right: 16px;
                }
            }
        }
    }
    .paperless-help-container {
        @include card-style;
    }
    .paperless-error {
        display: block;
        padding-left: 40px;
    }
    .not-found-message {
        width: 100%;
        display: flex;
        align-items: center;
        .warning-icon-container {
            margin-right: 8px;
        }
        .not-found-message-container {
            display: flex;
            align-items: center;
            height: 100%;
            min-height: 35px;
        }
    }
    .toggle-state-label {
        margin-bottom: 0;
    }
    .paperless-terms-title {
        margin-top: 16px;
        font-size: 16px; 
        font-style: normal;
        font-weight: 700;
        line-height: 25.6px;
    }
    .paperless-scroll-terms {
        margin-top: 8px;
        background-color: #F5F5F5;
        @media (min-width: 769px)  {
            margin-top: 12px;
            height: 248px; 
            overflow-y: scroll;
        }
    }
}
