/* -- Imports -- */
@import "global-styles";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "assets/scss/_variables.scss";
@import 'bootstrap/scss/bootstrap';

@import "src/app/app.component.scss";
@import "src/app/modules/communication/components/text-alerts/text-alerts.component.scss";
@import "src/app/modules/account/components/create-new/create-new.component.scss";
@import "src/app/modules/shared/components/agent-card/agent-card.component.scss";
@import "src/app/modules/esign/components/status/status.component.scss";
@import "src/app/modules/esign/components/esign-policy-cards/esign-policy-cards.component.scss";
@import "src/app/modules/shared/components/spinner/spinner.component.scss";
@import "src/app/modules/home/components/landing/landing.component.scss";
@import "src/app/modules/billing/components/billing-summary/billing-summary.component.scss";
@import "src/app/modules/communication/components/paperless-options/paperless-options.component.scss";
@import "src/app/modules/shared/components/alerts/alerts.component.scss";
@import "src/app/modules/shared/components/way-finder/way-finder.component.scss";
@import "src/app/modules/shared/components/feedback/feedback.component.scss";
@import "src/app/modules/shared/components/icon/icon.component.scss";
@import "src/app/modules/shared/components/modal/modal.component.scss";
@import "src/app/modules/shared/components/search/search.component.scss";
@import "src/app/modules/shared/components/password-input/password-input.component.scss";
@import "src/app/modules/shared/components/paperless-terms-content/paperless-terms-content.component.scss";
@import "src/app/modules/shared/components/select/select.component.scss";
@import "src/app/modules/shared/components/footer/footer.component.scss";
@import "src/app/modules/shared/components/input/input.component.scss";
@import "src/app/modules/shared/components/accordion/accordion.component.scss";
@import "src/app/modules/shared/components/link-paragraph/link-paragraph.component.scss";
@import "src/app/modules/shared/components/tooltip/tooltip.component.scss";
@import "assets/scss/hydra.css"; // uncomment for local development

@import "@lmig/safeco-ui/scss/customer/Button/Button";
@import "@lmig/safeco-ui/scss/Text/Small";
@import "@lmig/safeco-ui/scss/Link/TextLink";
@import "@lmig/safeco-ui/scss/Text/Info";
@import "@lmig/safeco-ui/scss/Text/Italic";
@import "@lmig/safeco-ui/scss/Toggle/Toggle";
@import "@lmig/safeco-ui/scss/Logo/Safeco/Logo";
@import "@lmig/safeco-ui/scss/Layout/List";

// @import "@lmig/lmds-styles/lm-styles";
// @import '@lmig/lmds-styles/_styles/base';
@import '@lmig/lmds-styles/_styles/Typography/heading';
@import '@lmig/lmds-styles/_styles/Grid/gridCol';
@import '@lmig/lmds-styles/_styles/Grid/gridRow';

/* -- Globals -- */

html,
body {
    height: 100%;
    font-size: 16px;
}

// For existing mobile app qualtrics feedback bar 
.state-app {
    .QSIFeedBackLink {
        font-size: 16px !important;
        cursor: pointer !important;
        background-color: #1772BA !important;
        width: 100% !important;
        height: 36px !important;
        overflow: hidden !important;
        position: relative !important;
        z-index: 2000000002 !important;
        bottom: auto !important;
        top: auto !important;
        left: 0px !important;
        padding: 8px;
        text-align: center;

        &::after {
            color: white;
            content: "SHARE YOUR FEEDBACK";
            z-index: 2000000003;
        }

        img {
            display: none !important;
        }
    }
}
