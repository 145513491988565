@import "@lmig/safeco-ui/scss/Form/Label";
@import "@lmig/safeco-ui/scss/Form/Input/Text";
@import '@lmig/safeco-ui/scss/Form/Input/Password';


.password-input {

  .multilineHelp {
    margin-top: 8px;

    .sco-list {
      margin: 0;
      //updating to match single line help text
      .sco-list-item {
        color: #565656;
        font-size: 12px;
        margin: 0 0 0 16px;
        padding: 0 0 8px 0;
        line-height: 16px;
      }
    }
  }

  .sco-input-visToggle {
    color: #343741;
    font-size: 0.75rem;
    font-weight: 400;
    height: auto;
    transform: translateY(0);
    line-height: 50px;
    margin-top: -50px;
    padding-right: 8px;
    padding-left: 8px;
    float: right;
    z-index: 3;
  }

  .sco-input-visToggle:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .sco-input-password {
    z-index: 0;
  }
}

.sco-label-password{
  margin: 0 0 26px 0;

  //only want to set is-focus margin to 0 when there is help text
  &.is-error {
      margin: 0;
  }
  &.is-helpText {
      margin: 0;
  }
}
