// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "global-styles";

.search-pipe {
  display: none;
  padding: 0 .75rem;
  color: $color-border-light;

  @include sco-breakpoint($sco-md) {
    display: flex;
    align-items: center;
  }
}

.search-component {

  // search input classes
  .search-form {
    background-color: #f5f5f5;
    border-radius: 3px;
    width: 100%;
    min-width: 216px;
  }

  .search-full-width {
    width: 100%;
    position: relative;
    z-index: 998;
  }

  .mat-mdc-form-field-infix {
    display: inline-flex;
  }

  .mat-mdc-text-field-wrapper {
    padding: 4px 8px;
  }
    
  .mdc-line-ripple, .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  input {
    font-size: .875rem;

    &::placeholder {
      color: $text-color-light;
    }
  }

  // search result classes
  .app-overlay-container {
    position: absolute;
    z-index: 900;
    font-size: 0;
    margin-top: -35px;
  }

  .mat-mdc-autocomplete-visible {
    padding: 35px 0px 16px 0px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, .14), 0px 3px 1px -2px rgba(0, 0, 0, .14), 0px 1px 6px 0px rgba(0, 0, 0, .14);
    border-radius: 3px;
    background-color: #f5f5f5;
  }

  .faq-results {
    background-color: #f5f5f5;
    line-height: 19px;

    a {
      color: #343741;
      font-size: 14px;
      font-weight: 100;
    }
  }

  .mat-mdc-option {
    min-height: 30px;
    height: 30px;
    padding: 0px 8px;

    :first-child {
      padding-top: 8px;
    }

    .mdc-list-item__primary-text {
      width: 100%;

      a {
        width: 100%;
        text-align: left !important;
        text-wrap: nowrap;
      }
    }
  }

  // search submit button
  .search-button {
    border: none;
    background-color: transparent;
    padding: 4px 0 0 0;
    margin-right: -8px;

    app-icon {
      padding: 4px 8px 0 8px;
    }
  }
}

.hide {
  display: none;
}