@import "global-styles";
.modal-component {
     ::-webkit-scrollbar {
        width: 14px;
        height: 18px;
        background: #FFF;
    }
    div.paperless-terms-scrollbar::-webkit-scrollbar {
        background: #F5F5F5;
    }
     ::-webkit-scrollbar-thumb {
        height: 6px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        -webkit-border-radius: 7px;
        background-color: rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
     ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }
     ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
    .modal-overlay-dark {
        position: fixed;
        background: rgba(0, 0, 0, 0.8);
        z-index: 999;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .modal-content {
            @include card-style;
            margin-bottom: 0px;
            z-index: 1000;
            max-width: 600px;
            width: auto;
            color: $text-color;
            display: flex;
            // Note: column-reverse causes scrollbar to default to bottom - setting only if close button is displayed
            // flex-direction: column-reverse;

            &.modal-content-sm {
                max-width: 350px;
            }
            &.scroll-enabled {
                max-height: 90vh;
                overflow-y: scroll;
            }
            &.content-only-scroll {
                max-height: 90vh;

                .modal-wrapper {
                    overflow-y: hidden !important;
                }
                
            }
            @media (max-width: $sco-sm) {
                max-width: 90%;
                max-height: 90%;
                overflow-y: auto;
                &.modal-content-sm {
                    max-width: 90%;
                    max-height: 90%;
                    overflow-y: auto;
                }
                .modal-wrapper {
                    overflow-y: auto;
                }

                &.mobile-full-screen {
                    display: flex;
                    justify-content: center;

                    max-width: 100%;
                    max-height: 100%;
                    height: 100%;
                }
            }
            .spinner-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .top-close-button-container {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 8px;
                .close-icon {
                    background: none;
                    border: none;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .modal-title {
                font-size: 24px;
                margin-bottom: 32px;
                font-weight: 600;
            }
            .modal-sub-heading {
                margin-bottom: 16px;
            }
            .modal-container {
                margin-bottom: 16px;
                // padding-left: 24px;
               // padding-right: 24px;
                &.margin-large-tb {
                    margin-top: 40px;
                    margin-bottom: 48px;

                    &.top {
                        margin-bottom: inherit;
                    }
                    
                    &.bottom {
                        margin-bottom: inherit;
                    }
                }

                &.margin-small-lr {
                    margin-left: 8px;
                    margin-right: 8px;
                }

                .lmds-button-dynamic-width {
                    width: 100%;
                }

                // add class when using content-only-scroll
                .content-scroll {
                    max-height: calc(70vh - 64px);
                    overflow-y: scroll;
                }
            }
            .eft-authorization-modal-container,
            .cc-authorization-modal-container {
                .modal-info-list {
                    ul {
                        padding-left: 24px;
                        li {
                            margin-bottom: 8px;
                        }
                    }
                    .modal-info-container {
                        margin-bottom: 16px;
                        .modal-info-title {
                            margin-bottom: 4px;
                            font-weight: 600;
                        }
                    }
                }
                @media (max-width: $sco-sm) {
                    font-size: 12px;
                    .modal-title {
                        font-size: 18px;
                    }
                }
            }
            .mail-certificate-success-container {
                .success-icon-container {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 30px;
                }
                .success-text {
                    text-align: center;
                    margin-bottom: 30px;
                }
            }

            .book-transfer {
                text-align: center;

                .update-link {
                    text-decoration: underline;
                }
            }

            .green-success {
                color: $color-success-text;
            }

            .update-autopay-success-container, .payment-settings-success-container, .postpone-payment-success-container {
                text-align: center;
                margin-top: 18px;
                padding-left: 32px;
                padding-right: 32px;
                margin-bottom: 32px;

                .success-icon-container {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 16px;
                }

                .success-title-container {
                    padding-bottom: 16px;

                    &.margin-bottom-large {
                        padding-bottom: 24px;
                    }

                    .success-title {
                        margin-bottom: 0;
                        position: inherit;
                        top: auto;
                        height: auto;
                    }
                }

                .messages {
                    padding-bottom: 8px;
                }

                @media (max-width: $sco-md) {
                    .success-title-container {
                        padding-top: 0;
                    }

                    .success-icon-container {
                        margin-bottom: 16px;
                    }
                   
                }
            }

            .back-button-container {
                display: flex;
                justify-content: flex-end;
            }
            .landing-policy-paperless-modal-container {
                .paperless-container {
                    display: flex;
                    flex-direction: column;
                    .paperless-spinner-overlay { // fix for spinner overlay to cover modal
                        position: fixed;
                    }
                    .paperless-header {
                        display: flex;
                        align-items: center;
                        margin-bottom: 16px;                   
                        .modal-title {
                            margin-bottom: 32px;
                        }
                        @media (max-width: $sco-sm) {
                            margin-bottom: 16px;
                            margin-top: 0;
                        }
                    }
                    .paperless-modal-title { // for CA Paperless (including new Non-CA Opt-in)
                        margin-bottom: 0;
                        font-size: 32px;
                        font-weight: 700;
                        line-height: 38.4px;
                        @media (max-width: 768px) {
                            font-size: 28px;
                            line-height: 33.6px;
                        }
                    }
                    .paperless-text {
                        margin-bottom: 16px;
                        margin-top: 32px;
                    }
                    .paperless-text-ca { // for CA Paperless (including new Non-CA Opt-in)
                        margin-top: 32px;
                        @media (max-width: 768px) {
                            margin-top: 16px;
                        }
                    }
                    .paperless-terms-title { // for CA Paperless (including new Non-CA Opt-in)
                        font-size: 16px; 
                        font-style: normal;
                        font-weight: 700;
                        line-height: 25.6px;
                    }
                    .paperless-scroll-terms { // for CA Paperless (including new Non-CA Opt-in)
                        margin-top: 8px;
                        background-color: #F5F5F5;
                        // put T&Cs in scrollable text area if screen is larger
                        @media (min-width: 769px)  {
                            margin-top: 12px;
                            height: 248px; 
                            overflow-y: scroll;
                        }
                    }
                    .paperless-tc-agree-text {
                        font-size: 12px;
                        line-height: 19.2px;
                        margin-top: 32px;
                        @media (max-width: 768px) {
                            margin-top: 24px;
                        }
                        .paperless-tc-agree {
                            font-weight: 700;
                        }
                    }
                    .paperless-modal-button-group {
                        margin-bottom: 0px;
                    }
                    .paperless-no-thanks-link {
                        margin-top: 24px;
                        margin-bottom: 16px;
                        width: 100%;
                        text-decoration: underline;
                    }
                    .paperless-terms-link {
                        margin-top: 8px;
                        margin-bottom: 16px;
                        font-size: 11px;
                        display: inline;
                        text-decoration: underline;
                    }
                    .paperless-submit-button-ca-paperless { // for CA Paperless (including new Non-CA Opt-in)
                        min-width: 250px;
                        margin-top: 32px; 
                        margin-bottom: 32px;
                        @media (max-width: 768px) {
                            min-width: unset;
                            margin-top: 24px;
                            margin-bottom: 24px;
                        }
                    }
                    .paperless-submit-button{
                        width: 250px;
                        margin-top:32px;
                        margin-bottom: 32px;
                    }
                }
                
            }
            .paperless-modal-margin {
                padding-left: 8px;
                padding-right: 8px;
            }
            .verify-remove-policy-modal-container {
                height: 270px;
                width: 250px;
                text-align: center;
                padding: 10px;
                .remove {
                    width: 235px;
                }
                .go-back {
                    margin-left: 0;
                    margin-top: 15px;
                }
                .spinner-card {
                    margin-top: 70px;
                }
            }
            .sms-opt-out-success-container {
                height: 270px;
                width: 300px;
                text-align: center;
                padding: 10px;

                .success-icon-container {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 16px;
                }

                .modal-title {
                    font-weight: 300;
                }
            }
            .cross-sell-modal-container {
                .cross-sell-title {
                    display: flex;
                    align-items: center;
                }
                .cross-sell-med-bold {
                    font-size: 20px;
                    font-weight: bold;
                    margin-top: 16px;
                }
                .cross-sell-med {
                    font-size: 20px;
                    margin-top: 16px;
                }
                .cross-sell-sml {
                    font-size: 16px;
                    margin-top: 16px;
                }
                .cross-sell-xs {
                    font-size: 11px;
                    display: inline-block;
                }
                .cross-sell-icon-sml {
                    margin-right: 15px;
                }
                .cross-sell-gold {
                    text-align: right;
                }
            }
            .policy-number-img-container {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: left;
            
                @include sco-breakpoint($sco-sm) {
                    justify-content: center;
                }
            
                div {
                    margin-top: 16px;
                    @include sco-breakpoint($sco-xs-only) {
                        margin-right: 16px;
                    }
                }
            
                span {
                    display: block;
                }

                .title {
                    font-size: 24px;
                    text-align:center;
                }
            }
            .add-vehicle-info{
                margin-bottom: 24px; 

                .new-vehicle-title{
                    margin-bottom: 24px;
                    font-weight: 300;
                    font-size: 40px;
                    letter-spacing: -0.5;
                    line-height: 1.3;
                    color: $sco-text-dark;
                }

                ul {
                    margin-left: 0px;
                    padding-left: 24px;

                    li {
                        margin-bottom: 8px;
                        
                        .list-description {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 1.313;
                            color: $sco-text-dark;
                        }
                    }
                }
                
                .navigation-buttons {
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: flex-end;
                    align-items: flex-end;

                    .continue-button {
                        display: block;
                    }

                    .agent-button {
                        margin-bottom: 24px;
                        display: block;
                    }
                }

                @media (max-width: $sco-sm) {
                    .navigation-buttons {
                        width: 100%;
                        text-align: center;
                    }       
                }
            }
            
            .exit-survey {
                .survey-textarea-container {
                    margin-bottom: 32px;

                    .progress-spinner {
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }

            .select-option-badge {

                &.margin-top {
                    margin-top: 32px;
                }

                &.margin-bottom {
                    margin-bottom: 32px;
                }
                
            }

            .sms-cta-container {
                .sms-text, .sms-cta-button {
                    margin-bottom: 32px;
                }
               
                .sms-no-thanks-link {
                    text-decoration: underline;
                }
            }

            .change-password-container {
                @media (max-width: 768px) {
                    .change-password-cancel {
                        margin-top: 16px;
                    }               
                }
            }

            #review-autopay-unenroll-container
            {
                h3 {
                    margin-top: 1em;
                }
                
                overflow-x: hidden;

                #unenroll-autopay-content {
                    p {
                        margin-bottom: 16px;
                    }

                    #autopay-unenroll-preview {
                        width: 100%;
    
                        .manual {
                            display: flex;
                            justify-content: flex-end;
                       }
    
                        #review-header {
                            width: 100%;
                            font-weight: bold;
                        }
    
                        .review-col {
                            #unenroll-popover {
                                position: relative;
                                bottom: 21px;
                                left: 8px;

                                .lmig-Popover-overlay {
                                    @media (max-width: 480px) {
                                        width: 70vw !important;
                                    }
                                   
                                }
                            }
                        }
    
                        .review-row {
                            width: 100%;
                            padding: 8px 0 8px 0;
                            border-bottom: 1px $color-border-light solid;
                            height: 41px;
                        }
                    }
    
                    #processing-fees-msg {
                        font-size: 14px;
                        padding-top: 8px;
                    }    
                }
            }
        }
    }
}
