@import "global-styles";
.paperless-scroll-text {
    margin-left: 8px;
    margin-right: 8px;

    .paperless-scroll-customer-info {
        margin-bottom: 24px;
    }
}
.paperless-scroll-terms-text {
    font-size: 12px;
    h3.paperless-scroll-terms-title, h4.paperless-scroll-terms-title {
        font-size: 14px;
        padding-bottom: 12px;
        margin-bottom: 0px;
    }
    ul.paperless-scroll-terms-ul {
        padding-inline-start: 24px;
    }
    ul.paperless-scroll-terms-ul-last {
        margin-bottom: 0px;
        padding-bottom: 8px;
    }
}