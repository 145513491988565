@import "@lmig/safeco-ui/scss/Form/Label";
@import "@lmig/safeco-ui/scss/Form/Input/Text";
@import "global-styles";

.input-component {
    .label-hierarchy {
        z-index: 1;
    }
    .multilineHelp {
        margin-top: 8px;

        .sco-list {
            margin: 0;

            //updating to match single line help text
            .sco-list-item {
                color: #565656;
                font-size: 12px;
                margin: 0 0 0 16px;
                padding: 0 0 8px 0;
                line-height: 16px;
            }
        }
    }

    &.sco-label-textInput {
        margin: 0 0 26px 0;
    
        //only want to set is-focus margin to 0 when there is help text
        &.is-error {
            margin: 0;
        }
        &.is-helpText.is-focused {
            margin: 0;
        }
        &.is-optional {
            margin: 0;
        }
        &.margin-bottom-small {
            margin-bottom: 8px;
        }
    }
    
    .sco-input:focus {
        outline: 1px solid $color-teal-medium;
        border-color: $color-teal-medium;
        box-shadow: 0 0 0 1px $color-teal-medium;
    }

    .stylized-help-icon {
        margin-right: 4px;
    }
    .info-style {
        color: $color-information-border;
    }
}
